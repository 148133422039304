import React from 'react';
import { useNavigate } from 'react-router-dom';
import PTButton from '../../components/Button/Button';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="w-full sm:w-1/2 md:w-3/4  lg:w-2/3 p-4">
        <h1 className="text-4xl md:text-5xl mb-6 font-inter-bold">
          Oops! That page can’t be found.
        </h1>
        <p className="text-white text-lg sm:text-xl md:text-2xl mb-6">
          It looks like nothing was found at this location. Maybe try one of the known links or
          press back to go to the previous page.
        </p>
        <PTButton
          label="Go back"
          primary
          medium
          className="font-inter-semibold"
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default PageNotFound;
