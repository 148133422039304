import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { PTTransparentIcon, SpinnerIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import Footer from '../../components/Footer';
import Modal from '../../components/Modal/Modal';
import ObDecorations from '../../components/ObDecorations';
import PTTextField from '../../components/TextField/TextField';
import { resetPasswordRequest, validateResetPasswordGuid } from '../../services/auth';
import { getQueryParam } from '../../shared/utils';
import SetNewPassword from './SetNewPassword';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const PasswordReset = () => {
  const guid = getQueryParam('GUID');
  const redirectURI = getQueryParam('redirect_uri');
  const redirectTo = redirectURI || global.config.HOME_PAGE;
  const [showModal, setShowModal] = useState(false);
  const [validatingResetPassToken, setValidatingResetPassToken] = useState(true);
  const [isResetPassTokenValid, setIsResetPassTokenValid] = useState(false);

  const validateGuid = async () => {
    try {
      const validatedGuid = await validateResetPasswordGuid(guid);
      setIsResetPassTokenValid(validatedGuid.status === 200);
      setValidatingResetPassToken(false);
    } catch (err) {
      if (guid) toast.error("Token expired or doesn't exist. Please try again.");
      setValidatingResetPassToken(false);
    }
  };

  useEffect(() => {
    validateGuid();
  }, []);

  const handleSendPasswordResetEmail = async (values) => {
    try {
      const response = await resetPasswordRequest(values.email);
      if (response.status === 200) {
        setShowModal(true);
      }
    } catch (error) {
      setShowModal(true);
    }
  };

  if (validatingResetPassToken) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <SpinnerIcon width={175} height={175} />
      </div>
    );
  }

  if (isResetPassTokenValid) {
    return <SetNewPassword guid={guid} />;
  }

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <div
        className="min-h-[350px] left-1/2 -translate-x-1/2 
        absolute rounded-xl bg-gray-900 mt-32 top-0 w-11/12 sm:w-[459px]"
      >
        <div className="px-3 sm:px-16 py-8">
          <div className="flex flex-row justify-between items-center mb-6">
            <div className="text-3xl font-inter-semibold">Password reset</div>
            <Link
              className="text-primary underline"
              to={`/login?redirect_uri=${redirectTo}`}
              replace
            >
              Log in
            </Link>
          </div>

          {/* Form */}
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={{ email: '' }}
            initialStatus={{ genericError: '' }}
            onSubmit={handleSendPasswordResetEmail}
          >
            {(props) => {
              const {
                values: { email },
                errors,
                touched,
                status: { genericError },
                handleSubmit,
                isSubmitting,
                handleChange,
                handleBlur,
              } = props;

              return (
                <Form>
                  <div>
                    <PTTextField
                      value={email}
                      label="Email"
                      name="email"
                      error={touched?.email && errors?.email}
                      placeholder="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300 focus:border-0"
                    />
                  </div>

                  <br />

                  <PTButton
                    label="Reset password"
                    primary
                    type="submit"
                    className="w-full h-14 mb-2 font-inter-semibold"
                    onClick={handleSubmit}
                    endIcon={isSubmitting ? <SpinnerIcon height={26} /> : null}
                  />

                  {genericError && <div className="mb-4 text-red-500 text-sm">{genericError}</div>}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <Modal showModal={showModal} onCloseRequest={() => window.location.replace('/login')}>
        <div className="text-center">
          <h2 className="text-2xl font-inter-semibold">Password change request</h2>
          <div className="mt-3">
            If the email address has been found, an email with instructions has been
            <br /> sent to the address that was provided.
          </div>
          <div className="w-full flex justify-center">
            <PTButton
              className="mt-4 w-1/2 sm:w-[150px]"
              label="Ok"
              primary
              medium
              onClick={() => window.location.replace(`/login?redirect_uri=${redirectTo}`)}
            />
          </div>
        </div>
      </Modal>
      <Footer />
    </div>
  );
};

export default PasswordReset;
