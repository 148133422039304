import React from 'react';
import { Rect1, Rect2, Vector1, Vector2 } from '../assets/icons';

const ObDecorations = () => {
  return (
    <div className="obDecorations select-none">
      <img src={Rect1} alt="playtreks" className="Rect1" />
      <img src={Rect2} alt="playtreks" className="Rect2 hidden md:block" />
      <img src={Vector1} alt="playtreks" className="Vector1 hidden md:block" />
      <img src={Vector2} alt="playtreks" className="Vector2" />
    </div>
  );
};

export default ObDecorations;
