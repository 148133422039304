import jsCookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerIcon } from './assets/icons';
import Login from './pages/Login/Login';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import Register from './pages/Register/Register';
import TwoFactorAuth from './pages/TwoFactorAuth/TwoFactorAuth';
import ValidateRegisterUser from './pages/ValidateRegisterUser/ValidateRegisterUser';
import VerifyAccount from './pages/VerifyAccount/VerifyAccount';
import { checkIfUserAuthenticated, genericRedirect, logoutUser } from './services/auth';
import { getQueryParam } from './shared/utils';

const Logout = () => {
  useEffect(() => {
    logoutUser();
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <SpinnerIcon width={175} height={175} />
    </div>
  );
};

const Home = ({ redirectTo }) => {
  return <Navigate to={`/login?redirect_uri=${redirectTo}`} replace />;
};

const SpotifyRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => genericRedirect('spotify', navigate), []);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <SpinnerIcon width={175} height={175} />
    </div>
  );
};

const App = () => {
  const [authenticatingUser, setAuthenticatingUser] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const redirectTo = getQueryParam('redirect_uri') || encodeURIComponent(global.config.HOME_PAGE);

  useEffect(() => {
    checkIfUserAuthenticated()
      .then(() => {
        if (window.location.pathname !== '/logout') {
          setRedirect(true);
        }
        setAuthenticatingUser(false);
      })
      .catch(() => {
        setAuthenticatingUser(false);
      })
      .finally(() => {
        const token = jsCookie.get('jwtToken');

        if (token && token !== 'undefined') {
          setTimeout(() => {
            if (window.opener) {
              window.opener.postMessage(token, process.env.REACT_APP_PLAYGROUNDS_APP_URL);
              window.close();
            }
          }, 1500);
        }
      });
  }, []);

  if (authenticatingUser) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <SpinnerIcon width={175} height={175} />
      </div>
    );
  }

  if (!authenticatingUser && redirect) {
    if (!redirectTo.includes('theplaygrounds.io')) {
      window.location.replace(decodeURIComponent(redirectTo));
    }

    return (
      // the below UI is not necessary as we are redirecting.
      // Just to keep it consistent!
      <div className="w-screen h-screen flex justify-center items-center">
        <SpinnerIcon width={175} height={175} />
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        <Routes>
          <Route index element={<Home redirectTo={redirectTo} />} />
          <Route path="login" element={<Login />} />
          <Route path="2fa/verify" element={<TwoFactorAuth />} />
          <Route path="account/verify" element={<VerifyAccount />} />
          <Route path="logout" element={<Logout />} />
          <Route path="register" element={<Register />} />
          <Route path="/validateUser" element={<ValidateRegisterUser />} />
          <Route path="passwordReset" element={<PasswordReset />} />
          <Route path="spotify/redirect" element={<SpotifyRedirect />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
