import React from 'react';
import * as S from './styles';

const PTTextField = (props) => {
  const { value: inputValue, label, name, error, disabled, placeholder, ...rest } = props;

  return (
    <S.TextFieldComponent disabled={disabled}>
      <S.Label value={inputValue} label={label} htmlFor={name}>
        {label}
      </S.Label>

      <S.TextInput
        {...rest}
        disabled={disabled}
        name={name}
        placeholder={label || placeholder || ''}
        label={label}
        value={inputValue}
      />

      {error && <S.Error>{error}</S.Error>}
    </S.TextFieldComponent>
  );
};

export default PTTextField;
