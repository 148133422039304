/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { PTTransparentIcon, SpinnerIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import Footer from '../../components/Footer';
import ObDecorations from '../../components/ObDecorations';
import PTTextField from '../../components/TextField/TextField';
import { resetPassword } from '../../services/auth';
import Modal from '../../components/Modal/Modal';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      `Must Contain at least One Uppercase, One Lowercase, 
      One Number and one special case Character`
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SetNewPassword = ({ guid }) => {
  const query = new URLSearchParams(window.location.search);
  const redirectURI = query.get('redirect_uri')
    ? query.get('redirect_uri')
    : global.config.HOME_PAGE;
  const [showModal, setShowModal] = useState(false);

  const handleSetNewPassword = async (values, formActions) => {
    document.cookie = `jwtToken= ; path=/; max-age=${0}; domain=${
      global.config.ssoAppDomain
    }; secure`;

    try {
      const response = await resetPassword(values.password, guid);

      if (response.status === 200) {
        setShowModal(true);
      }
    } catch (error) {
      if (error.response?.data?.msg) {
        formActions.setStatus({ genericError: error.response.data.msg });
      } else {
        toast.error('Something went wrong. Please try again later.');
      }
      formActions.setSubmitting(false);
    }
  };

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <div className="login-container absolute rounded-xl bg-gray-900 mt-32 top-0 sm:w-[459px]">
        <div className="px-3 sm:px-16 py-8">
          <div className="flex flex-row justify-between items-center mb-6">
            <div className="text-4xl font-inter-semibold">New password</div>
            <Link
              className="text-primary underline"
              to={redirectURI ? `/login?redirect_uri=${redirectURI}` : '/login'}
              replace
            >
              Log in
            </Link>
          </div>

          {/* Form */}
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={{ confirmPassword: '', password: '' }}
            initialStatus={{ genericError: '' }}
            onSubmit={handleSetNewPassword}
          >
            {(props) => {
              const {
                values: { password, confirmPassword },
                errors,
                touched,
                status: { genericError },
                handleSubmit,
                isSubmitting,
                handleChange,
                handleBlur,
              } = props;

              return (
                <Form>
                  <div>
                    <PTTextField
                      value={password}
                      type="password"
                      label="Password"
                      name="password"
                      error={touched?.password && errors?.password}
                      placeholder="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300"
                    />
                  </div>
                  <br />
                  <div>
                    <PTTextField
                      value={confirmPassword}
                      type="password"
                      label="Confirm password"
                      name="confirmPassword"
                      error={touched?.confirmPassword && errors?.confirmPassword}
                      placeholder="Confirm Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300"
                    />
                  </div>

                  <br />
                  <PTButton
                    label="Submit"
                    primary
                    type="submit"
                    className="w-full h-14 mb-2 font-inter-semibold"
                    onClick={handleSubmit}
                    endIcon={isSubmitting ? <SpinnerIcon height={26} /> : null}
                  />

                  {genericError && <div className="text-red-500 text-sm">{genericError}</div>}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      {/* Password changed confirmation modal */}
      <Modal showModal={showModal} onCloseRequest={() => window.location.replace('/login')}>
        <div className="text-center">
          <h2 className="text-2xl font-inter-semibold">Password changed</h2>
          <div className="mt-3">Your password has been successfully reset.</div>
          <div className="w-full flex justify-center">
            <PTButton
              className="mt-4 w-1/2 sm:w-[150px]"
              label="Ok"
              primary
              medium
              onClick={() => window.location.replace('/login')}
            />
          </div>
        </div>
      </Modal>

      <Footer />
    </div>
  );
};

export default SetNewPassword;
