import React from 'react';

const UserConsent = () => (
  <div className="text-center text-gray-300 text-xs mb-4">
    By continuing, you agree to the{' '}
    <a
      href="https://playtreks.com/privacypolicy"
      target="_blank"
      className="text-white underline"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
    &nbsp;
    <br />
    and the{' '}
    <a
      href="https://playtreks.com/terms"
      target="_blank"
      className="text-white underline"
      rel="noreferrer"
    >
      Terms of Service
    </a>
  </div>
);

export default UserConsent;
