/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Navigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { PTTransparentIcon, SpinnerIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import Footer from '../../components/Footer';
import ObDecorations from '../../components/ObDecorations';
import PTTextField from '../../components/TextField/TextField';
import { get2FAState, send2FASMSOTP, verify2FACode } from '../../services/auth';

const twoFAFormValidationSchema = Yup.object().shape({
  code: Yup.string().required('2FA Code is required').min(6, 'Code should be of minimum length 6'),
});

const helperText = {
  authApp: () => <>Please enter the code generated by your authenticator app</>,
  backupCode: () => <>Please enter one of your unused backup codes</>,
  sms: (phone) => (
    <>
      Please enter the code sent to your number ending with{' '}
      <b className="font-inter-semibold">****{phone?.substring(phone?.length - 4)}</b>
    </>
  ),
};

const TwoFactorAuth = () => {
  const location = useLocation();
  const jwtToken = location?.state?.token || '';
  const [page, setPage] = useState(0);
  const [verificationMethod, setVerificationMethod] = useState('authApp');
  const [twoFAState, setTwoFAState] = useState('');
  const [sendingSMS, setSendingSMS] = useState(false);
  const [loading, setLoading] = useState(false);
  const reCaptchaRef = useRef();

  const fetch2FASMSPhone = async () => {
    try {
      setLoading(true);
      const res = await get2FAState(jwtToken);
      if (res) {
        setTwoFAState(res.data);
        setVerificationMethod(res.data?.totp?.enabled ? 'authApp' : 'sms');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch2FASMSPhone();
  }, []);

  const handleTwoFactorAuth = async (values, formActions) => {
    try {
      const response = await verify2FACode(
        { code: values.code, method: verificationMethod },
        jwtToken
      );

      if (response.data.success) {
        if (window?.opener) {
          window.opener.postMessage(response.data.token, process.env.REACT_APP_PLAYGROUNDS_APP_URL);
          window.close();
        }

        Cookies.set('jwtToken', response.data.token, {
          path: '/',
          domain: global.config.ssoAppDomain,
          expires: 1, // 1 day
          secure: true,
        });

        if (!window.localStorage.getItem('playtreksNextStep')?.includes('theplaygrounds.io')) {
          window.location.replace(window.localStorage.getItem('playtreksNextStep'));
        }
      }
    } catch (error) {
      console.log(error);

      if (error.response.status === 429) {
        toast.error('Too many requests. Please try again later.');
      } else if (error.response?.data?.message || error.response?.data?.msg) {
        formActions.setStatus({
          genericError: error.response.data.message || error.response.data.msg,
        });
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleContinue = async () => {
    if (verificationMethod === 'sms') {
      setSendingSMS(true);
      try {
        const token = await reCaptchaRef.current.executeAsync();
        reCaptchaRef.current.reset();
        const res = await send2FASMSOTP(jwtToken, token);

        if (!res.data.success) {
          toast.error('Unable to send OTP. Please try again later.');
        }

        if (res.data.success) {
          toast.success(res.data.msg);
        }
        setSendingSMS(false);
      } catch (error) {
        reCaptchaRef.current.reset();

        toast.error(
          error?.response?.data?.msg || 'An unexpected error occurred. Please try again.'
        );
        setSendingSMS(false);
      }
    }
    setPage((currPage) => currPage + 1);
  };

  // const verifyCaptchaCallback = (response) {}

  const handleOnCaptchaError = () => {
    reCaptchaRef.current.reset();
  };

  const handleOnCaptchaExpiry = () => {
    reCaptchaRef.current.reset();
  };

  if (!jwtToken) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={process.env.REACT_APP_INVISIBLE_CAPTCHA_SITE_KEY}
        size="invisible"
        theme="dark"
        // onChange={verifyCaptchaCallback}
        onExpired={handleOnCaptchaExpiry}
        onErrored={handleOnCaptchaError}
      />

      <div
        className="absolute rounded-xl bg-gray-900 mt-32 top-0 
      left-1/2 -translate-x-1/2  w-11/12 sm:w-[479px] sm:min-h-[350px]"
      >
        <div className="flex flex-col px-3 sm:px-10 pt-8 justify-center items-center mb-8">
          <div className="text-4xl font-inter-semibold">Verify your identity</div>
        </div>

        <div className="px-3 sm:px-12 h-full">
          {page === 0 &&
            (loading ? (
              <div className="flex justify-center items-center h-[150px]">
                <SpinnerIcon />
              </div>
            ) : (
              <div className="mb-5">
                <p className="flex-none w-full text-white mb-4 text-lg">
                  Please choose your verification method and click continue.
                </p>
                <div className="text-center">
                  <p className="mb-2 text-gray-300 text-sm">Choose your method</p>
                  <select
                    className="bg-primary font-inter-semibold 
                            text-sm text-gray-900 p-4 md:py-3 cursor-pointer rounded"
                    value={verificationMethod}
                    onChange={(e) => setVerificationMethod(e.target.value)}
                    name="verificationMethod"
                  >
                    {twoFAState?.totp?.enabled && <option value="authApp">Authenticator</option>}
                    {twoFAState?.sms?.enabled && <option value="sms">SMS</option>}
                    {(twoFAState?.sms?.enabled || twoFAState?.totp?.enabled) && (
                      <option value="backupCode">Backup code</option>
                    )}
                  </select>
                </div>
                <div className="flex justify-end mt-12">
                  <PTButton
                    label={sendingSMS ? 'Sending OTP' : 'Continue'}
                    disabled={sendingSMS}
                    onClick={handleContinue}
                    endIcon={sendingSMS ? <SpinnerIcon height={26} /> : null}
                  />
                </div>
              </div>
            ))}

          {page === 1 && (
            <Formik
              validationSchema={twoFAFormValidationSchema}
              enableReinitialize
              initialValues={{
                code: '',
              }}
              initialStatus={{ genericError: '' }}
              onSubmit={handleTwoFactorAuth}
            >
              {(props) => {
                const {
                  values: { code },
                  errors,
                  touched,
                  status: { genericError },
                  handleSubmit,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                } = props;

                return (
                  <Form>
                    <div className="mb-4">
                      <div className="text-gray-300 text-sm mb-1 mt-3">
                        {helperText[verificationMethod](twoFAState?.sms?.phone)}
                      </div>

                      <div className="mb-5">
                        <PTTextField
                          value={code}
                          type="password"
                          label="2FA Code"
                          name="code"
                          error={touched?.code && errors?.code}
                          placeholder="2FA code"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="bg-gray-700 placeholder:text-gray-300"
                        />
                      </div>
                      {genericError && (
                        <div className="mb-4 text-red-500 text-sm">{genericError}</div>
                      )}

                      <div className="flex justify-end items-center mt-12">
                        <div className="hover:text-primary underline cursor-pointer mr-7">
                          <span className=" font-inter-semibold" onClick={() => setPage(0)}>
                            Back
                          </span>
                        </div>

                        <PTButton
                          label={isSubmitting ? 'Verifying' : 'Verify'}
                          small
                          primary
                          type="submit"
                          className="font-inter-semibold"
                          onClick={handleSubmit}
                          endIcon={isSubmitting ? <SpinnerIcon width={26} height={26} /> : null}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TwoFactorAuth;
