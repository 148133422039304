import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { PTTransparentIcon, SpinnerIcon } from '../../assets/icons';
import Footer from '../../components/Footer';
import ObDecorations from '../../components/ObDecorations';
import { validateRegisteredGuid } from '../../services/auth';

const ValidateRegisterUser = () => {
  const [searchParams] = useSearchParams();
  const [showValidateUserBlock, setShowValidateUserBlock] = useState(false);
  const [validating, setValidating] = useState(false);

  async function validateGuid() {
    setValidating(true);
    try {
      const validatedGuid = await validateRegisteredGuid({ guid: searchParams.get('GUID') });
      Cookies.remove('jwtToken', {
        path: '/',
        domain: global.config.ssoAppDomain,
        expires: 1, // 1 day
        secure: true,
      });

      setShowValidateUserBlock(validatedGuid.status === 200);
      setValidating(false);
    } catch (err) {
      setShowValidateUserBlock(false);
      setValidating(false);
    }
  }

  useEffect(() => {
    validateGuid();
  }, []);

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <div
        className="absolute left-1/2 -translate-x-1/2  w-11/12  rounded-xl 
      bg-gray-900 mt-32 top-0 sm:w-[469px] sm:min-h-[350px]"
      >
        <div className="px-3 sm:px-10 py-8">
          <div className="flex justify-center items-center mb-6">
            <div className="text-4xl font-inter-semibold">Verify account</div>
          </div>
          {showValidateUserBlock && (
            <div className="mb-5">
              <p className="text-white text-lg sm:text-xl lg:text-2xl">
                thank you for verifying your account! you are now officially on board 🎉
              </p>
              <p className="text-white text-lg sm:text-xl lg:text-2xl">proceed to login</p>
            </div>
          )}
          {validating && (
            <div className="flex justify-center items-center h-[150px]">
              <SpinnerIcon />
            </div>
          )}
          {!showValidateUserBlock && !validating && (
            <div className="mb-5">
              <p className="text-white text-lg sm:text-xl lg:text-2xl">
                oops! seems like this link isn't valid anymore. please log in and request a new
                link.
              </p>
            </div>
          )}
          <div className="flex justify-center">
            <Link
              className="text-primary underline text-xl tracking-wider font-inter-semibold"
              to="/login"
              replace
            >
              log in
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ValidateRegisterUser;
