/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Cross } from '../../assets/icons';
import Colors from '../../styles/Colors';
import Portal from '../Portal';

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
  padding: 1rem;
`;

const ModelContainer = styled.div`
  min-width: 500px;
  min-height: 350px;
  max-height: 90vh;
  padding: 0 1rem 1rem 1rem;
  background-color: ${Colors.colorDarkOne};
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 90vw;
    min-width: auto;
  }
`;

const Modal = ({ children, onCloseRequest, showModal }) => {
  const handleOnClose = () => {
    onCloseRequest?.();
  };

  useEffect(() => {
    if (showModal) {
      document.querySelector('.modal-overlay')?.classList.add('modal-show');
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('.modal-overlay')?.classList.remove('modal-show');
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [showModal]);

  return (
    <Portal selector="body">
      <ModalOverlay className="modal-overlay">
        <ModelContainer>
          <div
            className="flex flex-row mx-0 pt-3 pr-3 justify-end"
            role="button"
            onClick={handleOnClose}
          >
            <Cross width={24} className="cursor-pointer" />
          </div>

          {children}
        </ModelContainer>
      </ModalOverlay>
    </Portal>
  );
};

export default Modal;
