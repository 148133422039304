import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PTTransparentIcon, SpinnerIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import Footer from '../../components/Footer';
import ObDecorations from '../../components/ObDecorations';
import { resendValidationEmail } from '../../services/auth';

const VerifyAccount = () => {
  const [counter, setCounter] = useState(0);
  const [resending, setResending] = useState(false);
  const location = useLocation();
  const token = location?.state?.token || '';

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  const resendEmail = async () => {
    setResending(true);
    try {
      await resendValidationEmail(token);
      toast.success('Please check your email for a verification link');

      setResending(false);
      setCounter(60);
    } catch (err) {
      toast.error('An unexpected error occurred. Please try again.');
      setResending(false);
    }
  };

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <div
        className="absolute left-1/2 -translate-x-1/2  w-11/12  rounded-xl 
      bg-gray-900 mt-32 top-0 sm:w-[489px] sm:min-h-[350px]"
      >
        <div className="px-3 sm:px-10 py-8">
          <div className="flex justify-center items-center mb-6">
            <div className="text-4xl font-inter-semibold">Verify account</div>
          </div>

          <div className="mb-5">
            <p className="text-white text-lg sm:text-xl lg:text-xl">
              You need to verify your account before you can login. Please resend verification email
              and follow the link in the email.
            </p>
          </div>

          <div className="flex justify-center mb-4">
            <PTButton
              disabled={counter > 0}
              onClick={resendEmail}
              label={counter > 0 ? `Try again in ${counter}` : 'Resend email'}
              primary
              medium
              endIcon={resending ? <SpinnerIcon height={20} /> : null}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default VerifyAccount;
