/* eslint-disable camelcase */
import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { getJWT } from '../shared/utils';

export const checkIfUserAuthenticated = () => {
  const url = `${global.config.API_URL}/user/protected`;
  const config = {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  };
  return axios.get(url, config);
};

export const loginUser = async (email, password, captchaToken) => {
  const url = `${global.config.API_URL}/user/login`;

  return axios.post(url, {
    email,
    password,
    token: captchaToken,
  });
};

export async function registerUser(payload) {
  const url = `${global.config.API_URL}/user/register`;

  return axios.post(url, payload);
}

export async function validateRegisteredGuid(guid) {
  const url = `${global.config.API_URL}/user/validateRegisteredUserGuid`;

  return axios.post(url, guid);
}

export async function resendValidationEmail(token) {
  const url = `${global.config.API_URL}/user/resendValidationEmail`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(url, {}, config);
}

export async function resetPasswordRequest(email) {
  const url = `${global.config.API_URL}/user/resetPasswordRequest`;

  return axios.post(url, { email });
}

export async function validateResetPasswordGuid(guid) {
  const url = `${global.config.API_URL}/user/validateGuid`;

  return axios.post(url, { guid });
}

export async function resetPassword(newPassword, guid) {
  const url = `${global.config.API_URL}/user/resetPassword`;

  return axios.post(url, {
    password: newPassword,
    guid,
  });
}
export const logoutUser = () => {
  window.localStorage.removeItem('playtreksNextStep');

  Cookies.remove('jwtToken', {
    path: '/',
    domain: global.config.ssoAppDomain,
    expires: 1, // 1 day
    secure: true,
  });

  window.location.assign('/login');
};

export const verify2FACode = async (payload, token) => {
  const url = `${global.config.API_URL}/user/2fa/verify`;

  return axios.post(url, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const send2FASMSOTP = async (jwtToken, captchaToken) => {
  const url = `${global.config.API_URL}/user/2fa/sendSMSOTP`;

  return axios.post(
    url,
    { token: captchaToken },
    {
      headers: { Authorization: `Bearer ${jwtToken}` },
    }
  );
};

export const get2FAState = async (token) => {
  const url = `${global.config.API_URL}/user/2fa/state`;

  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const externalLoginCall = (service) => {
  const url = `${global.config.API_URL}/externalLogin/login`;
  return axios({
    method: 'get',
    url,
    params: {
      service,
      idPT: true,
    },
  });
};

const handleExternalLoginCallWithState = (service, res) => {
  const search = res.data.trim('?');
  const params = new URLSearchParams(search);
  const state = params.get('state');
  window.localStorage.setItem(`${service}State`, state);
  window.location.replace(res.data);
};

export async function handleSpotifyExternalLogin() {
  externalLoginCall('spotify').then((res) => handleExternalLoginCallWithState('spotify', res));
}

export function externalRedirectCall(
  code,
  state,
  storedState,
  oauth_token,
  oauth_verifier,
  service
) {
  const url = `${global.config.API_URL}/externalLogin/redirectCallback`;

  return axios({
    method: 'get',
    url,
    params: {
      service,
      code,
      state,
      storedState,
      oauth_token,
      oauth_verifier,
      idPT: true,
    },
  });
}

export function externalRedirectCallProtected(
  code,
  state,
  storedState,
  oauth_token,
  oauth_verifier,
  service,
  jwtToken
) {
  const url = `${global.config.API_URL}/externalLogin/redirectCallbackProtected`;

  return axios({
    method: 'get',
    url,
    params: {
      service,
      code,
      state,
      storedState,
      oauth_token,
      oauth_verifier,
      idPT: true,
    },
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
}

export async function genericRedirect(service, navigate) {
  const search = window.location.search.trim('?');
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const state = params.get('state');
  const oauth_token = params.get('oauth_token'); // oauth v1
  const oauth_verifier = params.get('oauth_verifier'); // oauth v1

  if (params.get('error')) {
    toast.error(`Could not log in with ${service}. Please try again.`);
    window.location.replace('/login');
  }

  const storedState = window.localStorage.getItem(`${service}State`);
  // TODO: display spinner, perhaps in the empty div?

  // If we have a JWT in local storage, then the user might be logged in, therefore
  // we call a separate route which is protected, since JWT passport
  // returns 401 immediately if the jwt token is not valid (or missing)
  //
  // TODO: find out how to avoid returning 401 in JWT? Two separate routes is not
  // too bad either.

  let jwtResponse;
  let locationToRedirect;
  let authenticated = false;

  try {
    await checkIfUserAuthenticated();
    authenticated = true;
  } catch (err) {
    authenticated = false;
  }

  // Check whether user is logged in using the api protected route, not existance of jwtToken

  try {
    if (!authenticated) {
      // user is not authenticated, "classical" case
      jwtResponse = await externalRedirectCall(
        code,
        state,
        storedState,
        oauth_token,
        oauth_verifier,
        service
      );
    } else {
      // user is authenticated, now we want to connect the additional accounts
      jwtResponse = await externalRedirectCallProtected(
        code,
        state,
        storedState,
        oauth_token,
        oauth_verifier,
        service,
        getJWT()
      );
    }

    const jwtToken = jwtResponse.data.jwt.token;

    if (jwtToken && jwtDecode(jwtToken)?.twoFARequired) {
      navigate('/2fa/verify', { replace: true, state: { token: jwtToken } });
      return;
    }

    Cookies.set('jwtToken', jwtToken, {
      path: '/',
      domain: global.config.ssoAppDomain,
      expires: 1, // 1 day
      secure: true,
    });

    if (jwtResponse.data.redirect_to_onboarding)
      locationToRedirect = `${global.config.APP_URL}/onboarding`;
    else locationToRedirect = window.localStorage.getItem('playtreksNextStep');

    window.location.replace(locationToRedirect);
  } catch (err) {
    // Oauth log in not successful
    toast.error(`Could not log in with ${service}. Please try again.`);
    window.localStorage.removeItem(`${service}State`);
    window.location.replace('/login');
  }
}
