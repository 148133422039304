/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { PTTransparentIcon, SpinnerIcon, SpotifyIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import Footer from '../../components/Footer';
import ObDecorations from '../../components/ObDecorations';
import PTTextField from '../../components/TextField/TextField';
import UserConsent from '../../components/UserConsent';
import { registerUser } from '../../services/auth';

const registerFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      `Must Contain at least One Uppercase, One Lowercase, 
      One Number and one special case Character`
    ),
  confirmPassword: Yup.string()
    .required('Please confirm you password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  captcha: Yup.string().required('Captcha verification is required'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  captcha: '',
};

const Register = () => {
  const query = new URLSearchParams(window.location.search);
  const redirectURI = query.get('redirect_uri')
    ? query.get('redirect_uri')
    : global.config.HOME_PAGE;
  const navigate = useNavigate();
  const reCaptchaRef = useRef(null);

  const handleOnRegister = async (values, formActions) => {
    document.cookie = `jwtToken= ; path=/; max-age=${0}; domain=${
      global.config.ssoAppDomain
    }; secure`;

    const user = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      password: values.password,
      token: values.captcha,
    };

    try {
      const response = await registerUser(user);

      if (response.data.success === true) {
        toast.success("You're all set! Please check your email to verify your account.");
        navigate(
          `/login?redirect_uri=${encodeURIComponent(`${global.config.APP_URL}/onboarding`)}`,
          { replace: true }
        );
      }
    } catch (error) {
      if (error.response?.data?.msg) {
        formActions.setStatus({ genericError: error.response.data.msg });
        reCaptchaRef?.current?.reset();
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      formActions.setSubmitting(false);
    }
  };

  const handleLogInWithSpotify = () => {};

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <div className="login-container absolute rounded-xl bg-gray-900 mt-32 top-0 sm:w-[459px]">
        <div className="px-3 sm:px-16 py-8 mb-4">
          <div className="flex flex-row justify-between items-center mb-6">
            <div className="text-4xl font-inter-semibold">sign up</div>
            <Link
              className="text-primary underline"
              to={redirectURI ? `/login?redirect_uri=${redirectURI}` : '/login'}
              replace
            >
              Log in
            </Link>
          </div>

          {/* Form */}
          <Formik
            validationSchema={registerFormValidationSchema}
            enableReinitialize
            initialValues={initialValues}
            initialStatus={{ genericError: '' }}
            onSubmit={handleOnRegister}
          >
            {(props) => {
              const {
                values: { firstName, lastName, email, password, confirmPassword },
                errors,
                touched,
                status: { genericError },
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                isSubmitting,
                handleChange,
                handleBlur,
              } = props;

              return (
                <Form>
                  <div>
                    <PTTextField
                      value={firstName}
                      label="First name"
                      name="firstName"
                      error={touched?.firstName && errors?.firstName}
                      placeholder="First name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300 focus:border-0"
                    />
                  </div>
                  <br />
                  <div>
                    <PTTextField
                      value={lastName}
                      label="Last name"
                      name="lastName"
                      error={touched?.lastName && errors?.lastName}
                      placeholder="Last name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300 focus:border-0"
                    />
                  </div>
                  <br />
                  <div>
                    <PTTextField
                      value={email}
                      label="Email"
                      name="email"
                      error={touched?.email && errors?.email}
                      placeholder="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300 focus:border-0"
                    />
                  </div>
                  <br />
                  <div>
                    <PTTextField
                      value={password}
                      type="password"
                      label="Password"
                      name="password"
                      error={touched?.password && errors?.password}
                      placeholder="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300"
                    />
                  </div>
                  <br />
                  <div>
                    <PTTextField
                      value={confirmPassword}
                      type="password"
                      label="Confirm password"
                      name="confirmPassword"
                      error={touched?.confirmPassword && errors?.confirmPassword}
                      placeholder="Confirm Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300"
                    />
                  </div>
                  <br />
                  <div className="flex justify-center">
                    <ReCAPTCHA
                      ref={reCaptchaRef}
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      render="explicit"
                      onChange={(res) => {
                        if (res) {
                          setFieldValue('captcha', res);
                          setFieldTouched('captcha', true);
                        } else setFieldValue('captcha', '');
                      }}
                      onExpired={() => setFieldValue('captcha', '')}
                      onErrored={() => setFieldValue('captcha', '')}
                      theme="dark"
                      size={window.innerWidth < 768 ? 'compact' : 'normal'}
                    />
                  </div>
                  {touched.captcha && errors.captcha && (
                    <div className="my-2 text-red-500 text-sm">{errors.captcha}</div>
                  )}

                  <br />
                  <PTButton
                    label="Register"
                    primary
                    className="w-full h-14 mb-5 font-inter-semibold"
                    onClick={handleSubmit}
                    endIcon={isSubmitting ? <SpinnerIcon height={26} /> : null}
                  />

                  {genericError && <div className="mb-4 text-red-500 text-sm">{genericError}</div>}

                  <div>
                    <PTButton
                      startIcon={
                        <img alt="Spotify" src={SpotifyIcon} className="loginSpotifyWhite" />
                      }
                      label="Log in with Spotify"
                      className="w-full h-14 mb-3 hover:text-color-primary spotifyButton"
                      onClick={handleLogInWithSpotify}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>

          <UserConsent />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Register;
